<template lang="pug">
div
	docs(:data="endpoints.getOffers")
</template>
<script>

import { defineComponent } from 'vue';
import Docs from '@/components/docs/Docs.vue';
import {GET_OFFERS_ENDPOINT} from '@/components/docs/constants';

export default defineComponent({
	components: {Docs},
	data() {
		return {
			endpoints: {
				getOffers: GET_OFFERS_ENDPOINT
			}
		}
	}
})
</script>
